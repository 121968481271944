import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceProvider } from '../models/service-provider';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  private baseUrl = `${environment.baseApiUrl}/api/v1/serviceproviders`;
  constructor(private http: HttpClient) { }

  getAllProviders(): Observable<ServiceProvider[]> {
    return this.http.get<ServiceProvider[]>(this.baseUrl);
  }

  createProvider(value: ServiceProvider) {
    return this.http.post(this.baseUrl, value);
  }
}
